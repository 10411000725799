import { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";
import clearAction from "../clearAction";

export default function BookReason(props) {

    let navigate = useNavigate();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    const [reasonList, setReasonList] = useState([]);
    const [terms, setTerms] = useState('');
    const { handleSubmit, setValue, getValues, watch } = useForm();
    const { actions, state } = useStateMachine({ updateAction, clearAction });

    useEffect(() => {
        async function fetchData() {

            //actions.clearAction();

            actions.updateAction({booking: {}});
            //console.log(state);

            fetch(sp + `/config.php?action=reasons&request=${state.login_code}`)
                .then(async response => {

                    const reasonListResult = await response.json();
                    setReasonList(reasonListResult.reasons);

                    if (reasonListResult.terms)
                        setTerms(reasonListResult.terms.data_value)

                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });

        }

        if (props.tabIndex === 0)
            fetchData();
    }, [props.tabIndex, actions, sp])

    function chooseReason(event, index) {
        //console.log(event.data);
        setValue('login_code', state.login_code);
        setValue('patient_id', state.patient.patient_id);
        setValue('practice_id', state.practice.practice_id);
        setValue('reason', event.data.id);
        setValue('reason_desc', event.data.reason_desc);
        setValue('fee', event.data.fee);
        setValue('duration', event.data.default_duration);
        //handleSubmit(onSubmit)();
    }

    const onSubmit = data => {
        if (getValues("reason")) {
            actions.updateAction({booking: data});
            //console.log(state);
            props.finishFnc();
        } else {
            alert('You need to choose a reason to continue')
        }
    };

    const reasonItem = data => {
        //console.log(data);
        let desc = [];
        if (data.description)
            desc.push(data.description)
        if (data.fee)
            desc.push(`a payment of ${process.env.REACT_APP_CURRENCY}${data.fee/100} is required for booking`);

        return (<div className="settings e-list-wrapper e-list-multi-line">
            <div className="reason-title">{data.reason_desc}</div>
            {desc &&
            <div className="reason-desc">{desc.join(' - ')}</div>
            }
        </div>);
    }

    function goTo(page) {
        navigate(page);
    }

    return (
        <div>
            <form>
                <h4 className="booking-text">What is the reason for your visit?</h4>
                <div className="reason-container">
                <ListViewComponent
                    select={chooseReason}
                    dataSource={reasonList}
                    template={reasonItem}
                    cssClass="e-list-template"
                    fields={{ text: 'reason_desc', value: 'id' }}
                />
                </div>
                <div className="space-between-mobile">
                <button className="booking-btns" type="button" onClick={() => handleSubmit(onSubmit)()} >Continue</button>
                <button className="booking-btns" type="button" onClick={() => goTo(`/home`)}>Cancel</button>
                </div>
            </form>
            {terms.length > 0 &&
                <div style={{padding: '10px'}} dangerouslySetInnerHTML={{__html:terms}}></div>
            }
        </div>
    )
}
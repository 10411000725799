import { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";
import clearAction from "../clearAction";

export default function BookClinician(props) {

    let navigate = useNavigate();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    const [clinicianList, setClinicianList] = useState([]);
    const { handleSubmit, setValue, getValues, watch } = useForm();
    const { actions, state } = useStateMachine({ updateAction, clearAction });

    useEffect(() => {
        async function fetchData() {
            //console.log(sp + `/config.php?action=clinicians&practice_id=${state.practice.practice_id}&reason=${state.booking.reason}`);

            fetch(sp + `/config.php?action=clinicians&practice_id=${state.practice.practice_id}&reason=${state.booking.reason}`)
                .then(async response => {

                    const clinicianListResult = await response.json();
                    clinicianListResult.clinicians.push({displayname: 'Any available clinician', staff_id: '-1'});
                    //console.log(clinicianListResult);
                    setClinicianList(clinicianListResult.clinicians);

                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });

        }

        if (props.tabIndex === 1)
            fetchData();
    }, [props.tabIndex, state, sp])

    function chooseClinician(event, index) {
        //console.log(event.data);
        setValue('clinician', event.data.staff_id);
        //handleSubmit(onSubmit)();
    }

    const onSubmit = data => {
        //console.log(getValues('clinician'));
        if (getValues('clinician')) {
            actions.updateAction(data);
            props.finishFnc(state.booking);
        } else {
            alert('You need to choose a clinician to continue');
        }
    };

    const clinicianItem = data => {
        //console.log(data);

        return (<div className="settings e-list-wrapper e-list-multi-line">
            <div className="reason-title">{data.displayname}</div>
        </div>);
    }

    function goTo(page) {
        navigate(page);
    }

    return (
        <div>
            <form>
                <h4 className="booking-text">Which clinician would you like to see?</h4>
                <div className="reason-container">
                <ListViewComponent
                    select={chooseClinician}
                    dataSource={clinicianList}
                    template={clinicianItem}
                    cssClass="e-list-template"
                    fields={{ text: 'displayname', value: 'staff_id' }}
                />
                </div>
                <div className="space-between-mobile">
                <button className="booking-btns" type="button" onClick={() => handleSubmit(onSubmit)()} >Continue</button>
                <button className="booking-btns" type="button" onClick={() => goTo(`/home`)}>Cancel</button>
                </div>
            </form>
        </div>
    )
}
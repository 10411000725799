import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';

import Layout from "./layout";
import BookClinician from "./booking/clinician";
import BookReason from "./booking/reason";
import BookDate from "./booking/date";
import BookPayment from "./booking/payment";
import BookConfirmation from "./booking/confirmation";
import dayjs from "dayjs";
import { useStateMachine } from "little-state-machine";

export default function Book(props) {
    let navigate = useNavigate();
    const tabRef = useRef();
    const { state } = useStateMachine();

    const [currentTab, setCurrentTab] = useState(0);
    //const { state } = useStateMachine({ updateAction });

    useEffect(() => {
        if (!state.login_code)
            navigate('/');

        tabRef.current.enableTab(0, true);
        tabRef.current.enableTab(1, false);
        tabRef.current.enableTab(2, false);
        tabRef.current.enableTab(3, false);
        tabRef.current.enableTab(4, false);

    }, [navigate])

    function tabSelecting(e) {
        //console.log(e);
        setCurrentTab(e.selectingIndex);

        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    function reasonChosen(e) {
        tabRef.current.enableTab(1, true);
        tabRef.current.select(1);
        //setCurrentTab(1);
    }

    function clinicianChosen(e) {
        tabRef.current.enableTab(2, true);
        tabRef.current.select(2);
        //setCurrentTab(1);
    }

    function dateChosen(state) {
        if (!state)
            return;

        if (state.fee) {
            tabRef.current.enableTab(3, true);
            tabRef.current.select(3);
            //setCurrentTab(2);
        } else {
            tabRef.current.enableTab(0, false);
            tabRef.current.enableTab(1, false);
            tabRef.current.enableTab(2, false);
            tabRef.current.enableTab(3, false);
            tabRef.current.enableTab(4, true);
            tabRef.current.select(4);
            //setCurrentTab(3);
        }
    }

    function paymentMade(e) {
        tabRef.current.enableTab(0, false);
        tabRef.current.enableTab(1, false);
        tabRef.current.enableTab(2, false);
        tabRef.current.enableTab(3, false);
        tabRef.current.enableTab(4, true);
        tabRef.current.select(4);
        //setCurrentTab(3);
    }

    return (

            <Layout>
            {window.innerWidth >= 500 && <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} style={{marginLeft: "20px"}}>
                    <Link color="inherit" href={`${process.env.REACT_APP_PATH}/home`}>Home</Link>
                    <Link color="textPrimary" href={`${process.env.REACT_APP_PATH}/appointments`}>Appointments</Link>
                    <p className="breadcrumb-highlight">Book Appointment</p>
                </Breadcrumbs>}
                <TabComponent heightAdjustMode='Auto' ref={tabRef} selecting={tabSelecting} >
                    <div className="e-tab-header">
                        <div>Reason for Visit</div>
                        <div>Clinician to see</div>
                        <div>Date and Time</div>
                        <div>Pay a deposit</div>
                        <div>Confirmation</div>
                    </div>
                    <div className="e-content">
                        <div><BookReason finishFnc={reasonChosen} tabIndex={currentTab} /></div>
                        <div><BookClinician finishFnc={clinicianChosen} tabIndex={currentTab} /></div>
                        <div><BookDate finishFnc={dateChosen} tabIndex={currentTab} /></div>
                        <div><BookPayment finishFnc={paymentMade} tabTime={dayjs()} tabIndex={currentTab} /></div>
                        <div><BookConfirmation tabIndex={currentTab} /></div>
                    </div>
                </TabComponent>
            </Layout>
    )
}
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { useStateMachine } from "little-state-machine";

import Layout from "./layout";

export default function Appointment(props) {
    let navigate = useNavigate();
    const [apptList, setApptList] = useState([]);
    const { state } = useStateMachine();

    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;

    useEffect(() => {
        if (!state.login_code)
            navigate('/');

        async function fetchData() {
            // load any existing appointments

            fetch(sp + `/appts.php?request=${state.login_code}`)
                .then(async response => {

                    const appointmentList = await response.json();

                    //console.log(appointmentList);

                    if (appointmentList.result === 'Success')
                        setApptList(appointmentList.appts);
                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });
        }

        fetchData();
    }, [navigate, sp])

    return (
        <Layout>
            {window.innerWidth >= 500 && <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} style={{marginLeft: "10px"}}>
                <Link color="inherit" href={`${process.env.REACT_APP_PATH}/home`}>Home</Link>
                <p className="breadcrumb-highlight">Appointments</p>
            </Breadcrumbs>}
            <button className="myappts-bookappt-btn" type="button" onClick={() => navigate('/book')}>Book Appointment</button>
            <p className="appointments-text">If there is a problem with any appointment, please contact reception.</p>
            <div className="e-bigger e-adaptive-demo">
                {window.innerWidth >= 915 ?
                        <GridComponent dataSource={apptList} height='100%' enableAdaptiveUI={true} 
                            className="dashboard-appts-horiz">
                            <ColumnsDirective>
                                <ColumnDirective field='date_display' headerText='Date' width='150' />
                                <ColumnDirective field='time_display' headerText='Time' width='80' />
                                <ColumnDirective field='duration' headerText='Duration' width='80' />
                                <ColumnDirective field='reason_desc' headerText='Reason' width='100' />
                                <ColumnDirective field='staff_displayname' headerText='Clinician' width='100' />
                            </ColumnsDirective>
                        </GridComponent>
                    :
                    <GridComponent id="adaptivedevice" dataSource={apptList} height='100%' enableAdaptiveUI={true} 
                        className="dashboard-appts-vert" rowRenderingMode="Vertical">
                        <ColumnsDirective>
                            <ColumnDirective field='date_display' headerText='Date' width='80' />
                            <ColumnDirective field='time_display' headerText='Time' width='80' />
                            <ColumnDirective field='duration' headerText='Duration' width='80' />
                            <ColumnDirective field='reason_desc' headerText='Reason' width='80' />
                            <ColumnDirective field='staff_displayname' headerText='Clinician' width='80' />
                        </ColumnsDirective>
                    </GridComponent>
                }
                </div>
        </Layout>
    )
}
import { useEffect, useState, useRef } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import dayjs from 'dayjs';

import StatusMessages, { useMessages } from './StatusMessages'

import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";

const CheckoutForm = (props) => {
    const [messages, addMessage] = useMessages();

    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required'
        });


        if (error) {
            setErrorMessage(error.message);
        } else {
            props.finishFnc();
        }
    }

    const fee = (props.state.booking.fee / 100).toFixed(2);

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button className='stripe_submit' disabled={!stripe}>Pay {process.env.REACT_APP_CURRENCY}{fee}</button>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>

    );
};

export default function Payment(props) {
    const [reasonTerms, setReasonTerms] = useState('')
    const [termsAccepted, setTermsAccepted] = useState(true);
    const [stripeKey, setStripeKey] = useState();
    const [stripePromise, setStripePromise] = useState();
    const [stripeSecret, setStripeSecret] = useState();
    const { state } = useStateMachine({ updateAction });
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;

    useEffect(() => {

        async function fetchData() {
            //console.log(state)

            let confEmail = '';
            if (state.patient.home_email)
                confEmail = state.patient.home_email;
            else if (state.patient.alt_email)
                confEmail = state.patient.alt_email;

            const secretOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({
                    paymentMethodType: 'card', currency: 'gbp', email: confEmail,
                    amount: state.booking.fee, practice_id: state.practice.practice_id,
                    patient_id: state.patient.patient_id
                })
            };

            //console.log(secretOptions);

            const intent = await (await fetch(sp + '/stripe/card.php', secretOptions)).json();

            setStripeSecret(intent.client_secret);

            //console.log((intent))

            const requestOptions = {
                method: "GET",
                mode: "cors",
            };
            const stripeConfig = await (await fetch(sp + `/config.php?action=stripe_key&practice_id=${state.practice.practice_id}`, requestOptions)).json();

            if (stripeConfig.result === 'Success') {
                //console.log(stripeConfig)
                setStripeKey(await loadStripe(stripeConfig.publishableKey));
            } else {
                console.error('There was an error!');
            }

        }

        if (props.tabIndex === 3)
            fetchData();
    }, [props.tabIndex]);

    useEffect(() => {
        setStripePromise(stripeKey);
    }, [stripeKey])

    useEffect(() => {

        async function fetchData() {
            const termsResult = await (await fetch(`${sp}/config.php?action=terms&reason=${state.booking.reason}`)).json();

            if (termsResult.result === 'Success') {
                if (termsResult.terms) {
                    setReasonTerms(termsResult.terms);
                    setTermsAccepted(false);
                } else {
                    setReasonTerms('');
                    setTermsAccepted(true);
                }
            } else {
                console.error('There was an error!');
            }


        }

        fetchData();
    }, [state.booking.reason]);

    const appearance = {
        theme: 'stripe'
    };


    const options = {
        // passing the client secret obtained in step 2
        clientSecret: stripeSecret,
        // Fully customizable with appearance API.
        appearance: appearance,
    };

    return (
        <>

            {stripePromise ?
                <Elements stripe={stripePromise} options={options}>
                    <div className="styled-div">
                        <h2 className="header">Your chosen appointment.</h2>
                        <p>{state.booking.reason_desc} on {dayjs(state.booking.selected_date, 'YYYYMMDD').format('dddd Do MMMM')} @ {state.display_time} with {state.clinician_name} for {state.booking.duration} minutes</p>
                    </div>

                    <h4 className="booking-text">Please make payment.</h4>
                    <div style={{ maxWidth: '550px', padding: '20px' }}>
                        <CheckoutForm state={state} {...props} />
                    </div>
                </Elements>
                :
                <div>Setting up payment request. One moment.</div>
            }

        </>
    );
};